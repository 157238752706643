﻿import $ from "jquery";
import "jquery-validation";
import 'jquery-ui-dist/jquery-ui.js';

window.GAScript = function (event, action, label, amount, GTMId) {
    (function (w, d, s, l, i) {
        w[l] = w[l] || [];
        w[l].push({
            'gtm.start': new Date().getTime(),
            event: 'gtm.js'
        });
        var f = d.getElementsByTagName(s)[0],
            j = d.createElement(s),
            dl = l != 'dataLayer' ? '&l=' + l : '';
        j.async = true;
        j.src =
            'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
        f.parentNode.insertBefore(j, f);
    })(window, document, 'script', 'dataLayer', GTMId);
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
        'event': event,
        'event_action': action,
        'event_label': label,
        'event_amount': amount
    })
}

//$(document).ready(function() {
//    var GATrackName = $("body").find('input#GATrackName').val();
//    console.log(GATrackName);
//    GAScript("Ad_Display", 'Test_Action', 'TestLabel', '', 'GTM-PW48M6M');

//});

$(document).ready(function () {
    $("body").find('[SimployertrackEvent]').each(function () {
        var Event = $(this).attr("SimployertrackEvent");
        var Action = $(this).attr("SimployertrackAction");
        var Label = $(this).attr("SimployertrackLabel");
        var Amount = $(this).attr("SimployertrackAmount");
        var Id = $(this).attr("SimployertrackId");

        var Exists = $(this).attr("onclick");
        if (!Exists)
            Exists = "";
        
        $(this).attr('onclick', "GAScript('" + Event + "', '" + Action + "', '" + Label + "', '" + Amount + "', '" + Id + "');" + Exists);
    });


    var GATrackName = $("body").find('input#GATrackName').val();

    $("body").find('a[data-dcjtrack]').each(function () {
        var GTMId = $(this).attr("data-dcjtrack");
        var URL = $(this).attr("href");
        var arr = URL.split("/");
        var Ad_name = "";
        var i = 1;
        while (Ad_name == "") {
            Ad_name = arr[arr.length - i];
            i++;
            if (i > 10)
                break;
        }
        if (Ad_name == "")
            Ad_name = URL;
        $(this).attr('onclick', "return GAScript('Ad_Click', '" + Ad_name + "', '" + GATrackName + "', '', '" + GTMId + "')");

        GAScript("Ad_Display", Ad_name, GATrackName, '', GTMId);
        
    });
});