﻿import $ from "jquery";
$(document).ready(function () {
    InitToggleRow();
    InitCarousels();
});

window.FaqToggleClick = function (faqbtn) {
    $(faqbtn).toggleClass("active");
    var panel = faqbtn.nextElementSibling;
    $(panel).toggle();
}

window.EnlargeImage = function (imgElement) {
    // Get the modal
    var modal = document.getElementById("enlargeModal");
    modal.style.display = "block";

    var modalImg = document.getElementById("image");
    modalImg.src = imgElement;
    
    // Get the <span> element that closes the modal
    var span = document.getElementsByClassName("close")[0];

    // When the user clicks on <span> (x), close the modal
    span.onclick = function () {
        modal.style.display = "none";
    }
}

window.InitToggleRow = function () {
    var btn = $('button.toggleBtn');
    btn.click(ToggleRowView);

    var hideOnLoad = btn.attr('hideOnLoad');
    if (hideOnLoad) {
        btn.click();
    }
}

window.ToggleRowView = function(event) {
    var btn = $(event.target);
    var hideText = btn.attr('hideText');
    var showText = btn.attr('showText');
    var currentRow = btn.closest('div.container').parent().parent();
    var nextRow = currentRow.next();

    if (btn.hasClass('show')) {
        btn.removeClass('show');
        btn.html(hideText);
        nextRow.show();
    } else {
        btn.addClass('show');
        btn.html(showText);
        nextRow.hide();
    }
}

window.InitCarousels = function() {
    $('.scroller').slick({
        dots: true,
        arrows: true,
        infinite: false,
        speed: 200,
        slidesToShow: 4,
        slidesToScroll: 2,
        adaptiveHeight: true,
        responsive: [
            {
                breakpoint: 1220,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 2,
                    infinite: false,
                    dots: true
                }
            },
            {
                breakpoint: 900,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2
                }
            },
            {
                breakpoint: 580,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    });
}