﻿import $ from "jquery";
$(document).ready(function () {
    /* mark parent of current site */
    var allParents = $('.menuItemCurrent').closest('li[class*="li-parent"]');

    $.each(allParents, function (i, val) {
        var item = $(val);
        var parent = item.children('a[class*="a-toggle"]');
        parent.addClass('menuItemCurrentParent')
    });
});

/* dropdowns for desktop */
window.toggleMenu = function(item) {

    var id = $(item).attr('toggle-parent-id');

    closeOpen(id);

    $(item).toggleClass('menuOpen');
    $(".hmenu .menuItem-" + id).toggle(0);

    setIcon($(item));
}

function closeOpen(currentId) {
    var allToClose = $('.hmenu .menuOpen');

    $.each(allToClose, function (i, toClose) {
        var closeId = $(toClose).attr('toggle-parent-id');
        if (closeId != currentId) {
            $(toClose).removeClass('menuOpen');
            $(".hmenu .menuItem-" + closeId).hide(0);
            setIcon($(toClose));
        }
    });
}

function setIcon(item) {
    var icon = item.find('.material-icons');
    if (item.hasClass('menuOpen')) {
        icon.html('keyboard_arrow_up');
    } else {
        icon.html('keyboard_arrow_down');
    }
}

/* dropdowns for mobile */
window.toggleMobileMenu = function(item) {

    var id = $(item).attr('toggle-parent-id');

    //closeOpen(id);

    $(item).toggleClass('menuOpen');
    $("#vmenu .menuItem-" + id).toggle(0);

    setMobileIcon($(item));
}

function setMobileIcon(item) {
    var icon = item.find('.material-icons');
    if (item.hasClass('menuOpen')) {
        icon.html('expand_less');
    } else {
        icon.html('expand_more');
    }
}

/* trigger and open/close the mobile site menu  */
window.openSiteNav = function() {

    document.getElementById("vmenu").style.display = "block";
    document.getElementById("close-site-nav").style.display = "block";
    document.getElementById("show-site-nav").style.display = "none";
    $("body").addClass("noScroll");
}

window.closeSiteNav = function () {
    document.getElementById("vmenu").style.display = "none";
    document.getElementById("close-site-nav").style.display = "none";
    document.getElementById("show-site-nav").style.display = "inline-block";
    $("body").removeClass("noScroll");
}

$('.menu-toggle').click(function () {
    $('#site-overlaymenu').slideToggle('medium');
    $("body").toggleClass("noScroll");
    $("#header").toggleClass("sitemenu-open");
})


$(window).scroll(function () {
    var scroll = $(window).scrollTop();

    if (scroll >= 50) {
        $("#site-menu-container").addClass("cropHeader");
    } else {
        $("#site-menu-container").removeClass("cropHeader");
    }
});

window.openLoginMenu = function() {

    var element = document.getElementById("loginoptions");
    element.classList.toggle("show-options");
}


window.closeNav = function() {
    //document.getElementById("site-overlaymenu").style.display = "none";
    document.getElementById("closebtn").style.display = "none";
    document.getElementById("show-nav").style.display = "block";
    $("body").removeClass("noScroll");
    $("#header").removeClass("sitemenu-open");
}

window.openSearch = function() {
    document.getElementById("search-container").style.display = "block";
    document.getElementById("show-search").style.display = "none";
    document.getElementById("close-search").style.display = "block";
}

window.closeSearch = function() {
    document.getElementById("search-container").style.display = "none";
    document.getElementById("show-search").style.display = "block";
    document.getElementById("close-search").style.display = "none";
}

window.$ = window.jQuery = require('jquery');