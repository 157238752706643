﻿import $ from "jquery";
import "jquery-validation";
import 'jquery-ui-dist/jquery-ui.js';
$("#leadForm").validate({
    rules: {
        Conditions: { required: true },
        CompanyName: { required: true },
        FirstName: { required: true },
        LastName: { required: true },
        MobilePhone: { required: true },
        Address: { required: true, email: true },
    },
    messages: {
        CompanyName: { required: "@t.PleaseEnterYourCompany" },
        FirstName: { required: "@t.PleaseEnterYourFirstName" },
        LastName: { required: "@t.PleaseEnterYourLastName" },
        MobilePhone: { required: "@t.PleaseEnterYourPhoneNumber" },
        Address: { required: "@t.PleaseEnterYourEmail", email: "@t.PleaseEnterAcorrectEmail" },
        Conditions: { required: "@t.PleaseAcceptOutConditions" }

    },
    errorPlacement: function (error, element) {
        if (element.is(":radio")) {
            error.appendTo(element.parents('.container'));
        }
        else if (element.is(":checkbox")) {
            error.appendTo(element.parent());
        }
        else {
            error.insertAfter(element);
        }
    },

    submitHandler: function (form) {
        marketPlaceOrderFormSubmit();
        var thankYouPageUrl = $('#thankYouPage').val();
        $.ajax({
            type: "POST",
            url: '/umbraco/Surface/LeadForm/Post',
            data: $("#leadForm").serialize(),
            success: function (data) {

                if (thankYouPageUrl == null || thankYouPageUrl === "") {
                    $(".leadToggleClass").toggle();
                }
                else {
                    window.location.replace(thankYouPageUrl);
                }
            }
        });
        document.getElementById('ctabutton3').style.display = 'none';
        $(".spinner-large").show();
    }
});
$(".email").hide();

function marketPlaceOrderFormSubmit() {
    var onSubmittingFormJavascript = document.getElementById('leadFormWrapper');
    onSubmittingFormJavascript.getAttribute('data-leadFormSubmit');
}


$(document).ready(function () {
    var leadFormWrapper = document.getElementById("leadFormWrapper");
    if (leadFormWrapper) {
        $("#CompanyName").autocomplete({
            source: function (request, response) {
                var orgUrlApi = document.getElementById("leadFormWrapper").getAttribute("data-leadFormOrgApiUrl");

                $.ajax({
                    url: orgUrlApi + "?s=" + $('#CompanyName').val(),
                    dataType: "jsonp",
                    data: {
                        term: request.term
                    },
                    success: function (data) {
                        response(data);
                    }
                });
            },
            minLength: 2,
            select: function (event, ui) {
                $('#CPQ_OrganizationNumber').val(ui.item.id);
                $('#CPQ_Number_of_Employees').val(ui.item.numberOfEmployees);
            }
        });
    }

});

window.$ = window.jQuery = require('jquery');


