﻿import $ from "jquery";

$(document).ready(function () {
    // mark parent of current site 
    var allParents = $('.menuItemCurrent').closest('li[class*="li-parent"]');
    $.each(allParents, function (i, val) {
        var item = $(val);
        var parent = item.children('a[class*="a-toggle"]');
        parent.addClass('menuItemCurrentParent')
    });
});


$(document).ready(function () {
    // If Window URL matches href URL add CSS class
    $("[href]").each(function () {
        if (this.href == window.location.href) {
            $(this).addClass("menuItemCurrent");
        }
    });
});

/* dropdowns for desktop */
function toggleMenu(item) {

    var id = $(item).attr('toggle-parent-id');

    closeOpen(id);

    $(item).toggleClass('menuOpen');
    $(".headermenu .menuItem-" + id).toggle(0);

    setIcon($(item));
}

function closeOpen(currentId) {
    var allToClose = $('.headermenu .menuOpen');

    $.each(allToClose, function (i, toClose) {
        var closeId = $(toClose).attr('toggle-parent-id');
        if (closeId != currentId) {
            $(toClose).removeClass('menuOpen');
            $(".headermenu .menuItem-" + closeId).hide(0);
            setIcon($(toClose));
        }
    });
}

function setIcon(item) {
    var icon = item.find('.material-icons');
    if (item.hasClass('menuOpen')) {
        icon.html('keyboard_arrow_up');
    } else {
        icon.html('keyboard_arrow_down');
    }
}
window.$ = window.jQuery = require('jquery');