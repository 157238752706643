﻿import $ from "jquery";
import "jquery-validation";
import 'jquery-ui-dist/jquery-ui.js';


$("#twoStepLeadForm").validate({
    rules: {
        Conditions: { required: true },
        CompanyName: { required: true },
        FirstName: { required: true },
        LastName: { required: true },
        MobilePhone: { required: true },
        Address: { required: true, email: true },
    },
    messages: {
        CompanyName: { required: "@t.PleaseEnterYourCompany" },
        FirstName: { required: "@t.PleaseEnterYourFirstName" },
        LastName: { required: "@t.PleaseEnterYourLastName" },
        MobilePhone: { required: "@t.PleaseEnterYourPhoneNumber" },
        Address: { required: "@t.PleaseEnterYourEmail", email: "@t.PleaseEnterAcorrectEmail" },
        Conditions: { required: "@t.PleaseAcceptOutConditions" }

    },
    errorPlacement: function (error, element) {
        if (element.is(":radio")) {
            error.appendTo(element.parents('.container'));
        }
        else if (element.is(":checkbox")) {
            error.appendTo(element.parent());
        }
        else {
            error.insertAfter(element);
        }
    },
    submitHandler: function (form) {
        GAScript('Form_Submission', 'TwostepForm(Step_2)', '', '', '@GTMId')
        leadFormOnSubmiting();
        var thankYouPageUrl = $('#TwoStep_thankYouPage').val();
        $.ajax({
            type: "POST",
            url: '/umbraco/Surface/LeadForm/Post',
            data: $("#twoStepLeadForm").serialize(),
            success: function (data) {

                if (thankYouPageUrl == null || thankYouPageUrl === "") {
                    $(".leadToggleClass").toggle();
                }
                else {
                    window.location.replace(thankYouPageUrl);
                }
            }
        });
        document.getElementById('ctabutton3').style.display = 'none';
        $(".spinner-large").show();
    }
});
$(".email").hide();

function leadFormOnSubmiting() {
    const onSubmittingJavascript = document.getElementById("twoStepLeadFormWrapper");
    onSubmittingJavascript.getAttribute('data-twoStepFormSubmit');
}

const twoFormOrganizationApiUrl = document.getElementById("twoStepLeadFormWrapper")?.getAttribute("data-twoFormApiUrl");


$(function () {
    
    $("#TwoStep_CompanyName").autocomplete({
        source: function (request, response) {
            $.ajax({
                url: `${twoFormOrganizationApiUrl}?s=` + $('#TwoStep_CompanyName').val(),
                dataType: "jsonp",
                data: {
                    term: request.term
                },
                success: function (data) {
                    response(data);
                }
            });
        },
        minLength: 2,
        select: function (event, ui) {
            $('#TwoStep_CPQ_OrganizationNumber').val(ui.item.id);
            $('#TwoStep_CPQ_Number_of_Employees').val(ui.item.numberOfEmployees);
        },
    });
});

function SaveEmail() {
    var autofilledEmail = document.getElementById("autofilledEmail");
    if (autofilledEmail) {
        var email = document.getElementById("TwoStep_Address").value;
        autofilledEmail.value = email;
    }
}
const steps = Array.from(document.querySelectorAll("#twoStepLeadForm .step"));
const nextBtn = document.querySelectorAll("#twoStepLeadForm .next-btn");
const prevBtn = document.querySelectorAll("#twoStepLeadForm .btn-previous");
const form = document.querySelector("#twoStepLeadForm")

nextBtn.forEach((button) => {
    button.addEventListener("click", () => {
        SaveEmail();
        if ($('#TwoStep_Address').valid()) {
            changeStep("next");
        }
    });
});

prevBtn.forEach((button) => {
    button.addEventListener("click", () => {
        changeStep("prev");
    });
});

function changeStep(btn) {
    let index = 0;
    const active = document.querySelector(".active");
    index = steps.indexOf(active);
    steps[index].classList.remove("active");
    if (btn === "next") {
        index++;
    } else if (btn === "prev") {
        index--;
    }
    steps[index].classList.add("active");

    if (index == 1) {
        document.getElementById("btn-submit").disabled = false;
    }
}